<template>
  <div id="center-management">
    <v-row no-gutters>
      <v-col md="12">
        <div class="pa-10 pt-5">
          <div>
            <h2 class="d-flex">
              <v-icon @click="back()">arrow_back_ios</v-icon>
              <div class="d-flex align-center">Student Attendance</div>
            </h2>
            <div class="success">
              <v-alert
                :value="checkSuccess"
                transition="scale-transition"
                type="success"
                style="font-size: 14px; padding: 12px"
              >
                Update attendance successfully!
              </v-alert>
            </div>
            <h3 class="pt-3 pb-2">
              <span class="title-row" style="margin-left: 0">Class:</span>
              <span class="sub-title-row" style="color: #246aff">{{ classCode }}</span>
              <span class="title-row">Shift:</span>
              <span class="sub-title-row">{{ classTime }}</span>
              <span class="title-row">Weekday:</span>
              <span class="sub-title-row">{{ dayOfWeek }}</span>
              <span class="title-row">Date:</span>
              <span class="sub-title-row">{{ date }}</span>
            </h3>
          </div>
          <v-divider></v-divider>
          <div class="d-flex align-center mb-2 mt-2">
            <div>
              <span v-if="students.length > 1"
                ><span class="title-row" style="margin-left: 0; font-size: 14px">Total:</span>
                <span class="sub-title-row">{{ students.length }} students</span></span
              >
              <span v-else
                ><span class="title-row" style="margin-left: 0; font-size: 14px">Total:</span>
                <span class="sub-title-row">{{ students.length }} student</span></span
              >
            </div>
            <div class="attendance-button pl-10" @click="attendanceAll()" style="color: #246aff">
              Mark attendance all
            </div>
            <div class="attendance-button pl-10" @click="homeworkAll()" style="color: #246aff">
              Mark homework all
            </div>
          </div>
          <div class="pb-10">
            <v-progress-linear
              v-if="isLoading"
              indeterminate
              color="primary"
              class="my-10"
            ></v-progress-linear>
            <v-data-table
              v-if="!isLoading"
              :headers="headers"
              :items="students"
              :items-per-page="100"
              hide-default-footer
              class="elevation-1"
              fixed-header
              height="70vh"
            >
              <template v-slot:[`item.no`]="{ index }">
                {{ index + 1 }}
              </template>

              <template v-slot:[`item.photo`]="{ item }">
                <v-avatar size="60">
                  <img class="pa-2" v-if="!item.photo" src="@/assets/images/ImageProfile.jpg" />
                  <img v-else :src="item.photo" />
                </v-avatar>
              </template>

              <template v-slot:[`item.studentCode`]="{ item }">
                <div>
                  {{ item.studentCode }}
                </div>
              </template>
              <template v-slot:[`item.fullname`]="{ item }">
                <div>{{ item.fullName }}</div>
              </template>
              <template v-slot:[`item.mobilePhone`]="{ item }">
                <p>{{ item.mobilePhone }}</p>
              </template>

              <template v-slot:[`item.attendanceStatus`]="{ item }">
                <div class="d-flex align-center" style="position: relative">
                  <label>
                    <input
                      type="radio"
                      value="Attended"
                      v-model="item.attendanceStatus"
                      @click="handleClick('Attended', item)"
                    />
                    <span
                      :class="
                        checkRadio(item.attendanceStatus, 'Absent')
                          ? 'checkbox-custom-left'
                          : 'checkbox-custom-left checkbox-custom-left-focus'
                      "
                      >Attend</span
                    >
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="Absent"
                      v-model="item.attendanceStatus"
                      @click="handleClick('Absent', item)"
                    />

                    <span
                      :class="
                        checkRadio(item.attendanceStatus, 'Attended')
                          ? 'checkbox-custom-right'
                          : 'checkbox-custom-right checkbox-custom-right-focus'
                      "
                      >Absent</span
                    >
                  </label>
                </div>
              </template>
              <template v-slot:[`item.remarkAttendance`]="{ item }">
                <div style="position: relative">
                  <v-text-field
                    class="text-field-custom-1"
                    v-model="item.remarkAttendance"
                    dense
                    placeholder="Nhập lý do nghỉ"
                    outlined
                    height="40"
                    style="width: 280px"
                    v-if="
                      item.attendanceStatus == 'Absent' && !checkLength(item.remarkAttendance, 36)
                    "
                  ></v-text-field>
                  <v-textarea
                    v-else-if="item.attendanceStatus == 'Absent'"
                    rows="3"
                    style="width: 280px; margin: 15px 0 0 -7px"
                    v-model="item.remarkAttendance"
                    placeholder="Nhập lý do nghỉ"
                    outlined
                    dense
                  ></v-textarea>
                </div>
              </template>

              <template v-slot:[`item.homeworkStatus`]="{ item }">
                <div class="d-flex align-center" style="position: relative">
                  <label>
                    <input
                      type="radio"
                      v-model="item.homeworkStatus"
                      value="Yes"
                      @click="handleClickHomework('Yes', item)"
                    />
                    <span
                      :class="
                        checkRadio(item.homeworkStatus, 'No')
                          ? 'checkbox-custom-left'
                          : 'checkbox-custom-left checkbox-custom-left-focus'
                      "
                      >Done</span
                    >
                  </label>
                  <label>
                    <input
                      type="radio"
                      v-model="item.homeworkStatus"
                      value="No"
                      @click="handleClickHomework('No', item)"
                    />
                    <span
                      :class="
                        checkRadio(item.homeworkStatus, 'Yes')
                          ? 'checkbox-custom-right'
                          : 'checkbox-custom-right checkbox-custom-right-focus'
                      "
                      style="width: 80px"
                      >Not done</span
                    >
                  </label>
                </div>
              </template>
              <template v-slot:[`item.remarkHomeWork`]="{ item }">
                <div style="position: relative">
                  <v-text-field
                    class="text-field-custom-2"
                    v-model="item.remarkHomeWork"
                    dense
                    placeholder="Ghi chú"
                    outlined
                    height="40px"
                    style="width: 280px"
                    v-if="!checkLength(item.remarkHomeWork, 36)"
                  >
                  </v-text-field>
                  <v-textarea
                    v-else
                    rows="3"
                    style="width: 280px; margin: 15px 0 -1px 20px"
                    v-model="item.remarkHomeWork"
                    placeholder="Ghi chú"
                    outlined
                    dense
                  ></v-textarea>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-footer fixed absolute height="60" color="#fff" class="pl-8 pr-8">
      <v-col md="12">
        <div class="d-flex justify-end">
          <div>
            <v-btn
              color="secondary"
              @click="
                dialog = true
                save()
              "
              >Save</v-btn
            >
          </div>
        </div>
      </v-col>
    </v-footer>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent width="350">
        <v-card class="mx-auto pa-10">
          <div class="text-center">
            <v-btn
              elevation="0"
              height="36"
              class="pl-4 pr-4 mr-3 mb-5"
              :loading="dialog"
              style="background: white"
            >
            </v-btn>
          </div>
          <div class="text-center">
            <b style="font-size: 1.5rem">Saving!</b><br />
            <span>Please wait...</span>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="errorDialog" persistent width="350">
        <v-card class="mx-auto pa-6">
          <div class="text-center">
            <h1>Error!</h1>
            <p>This class has no students!</p>
          </div>
          <div class="text-center pt-4">
            <v-btn color="error" elevation="0" @click="back()" outlined> Back </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
// import { teacher_information } from "@/api/teacher-information.js";
import { class_managerment } from '@/api/class-management.js'

export default {
  components: {},
  data() {
    return {
      isLoading: false,
      scheduleID: this.$route.query.scheduleID,
      classID: this.$route.query.classID,

      teacherID: JSON.parse(localStorage.getItem('currentUser')).userInfo.teacherID,
      headers: [
        {
          text: 'No',
          align: 'start',
          value: 'no',
          width: '50px',
          sortable: false,
        },
        { text: 'Avatar', value: 'photo', sortable: false, width: '100px' },
        {
          text: 'Student code',
          value: 'studentCode',
          sortable: false,
          width: '20px',
        },
        { text: 'Student Name', value: 'fullName', sortable: false, width: '200px' },
        { text: 'Mobile phone', value: 'mobilePhone', sortable: false, width: '180px' },
        {
          text: 'Attendance',
          value: 'attendanceStatus',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Note Attendance',
          value: 'remarkAttendance',
          sortable: false,
          width: '300px',
        },
        {
          text: 'Homework',
          value: 'homeworkStatus',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Note Homework',
          value: 'remarkHomeWork',
          sortable: false,
          width: '300px',
          align: 'center',
        },
      ],
      noteAttendance: '',
      noteHomework: '',
      students: [],
      checkSuccess: false,
      status: '',
      classCode: '',
      classTime: '',
      date: '',
      dayOfWeek: '',
      dialog: false,
      takeAttendanceFromTeacher: localStorage.getItem('takeAttendanceFromTeacher') || null,
      errorDialog: false,
      attendanceAllValue: '',
      homeworkAllValue: '',
    }
  },
  created() {
    this.init()
  },
  methods: {
    checkLength(note, length) {
      return note?.length > length
    },
    checkRadio(item, text) {
      return item === text || item === '' || item === null
    },
    handleClick(value, item) {
      if (item.attendanceStatus === value) {
        item.attendanceStatus = null // Prevent unchecking
      }
    },
    handleClickHomework(value, item) {
      if (item.homeworkStatus === value) {
        item.homeworkStatus = null // Prevent unchecking
      }
    },
    async init() {
      this.isLoading = true
      await class_managerment.getClassScheduleDetail(this.scheduleID).then(res => {
        if (res) {
          this.classCode = res.syllabusItem.scheduleInfos.classCode
          this.classTime = res.syllabusItem.scheduleInfos.classTime
          this.date = this._dayjs(res.syllabusItem.scheduleInfos.date).format('DD/MM/YYYY')
          this.dayOfWeek = new Date(res.syllabusItem.scheduleInfos.date).getDay()
          switch (this.dayOfWeek) {
            case 0:
              this.dayOfWeek = 'Sunday'
              break
            case 1:
              this.dayOfWeek = 'Monday'
              break
            case 2:
              this.dayOfWeek = 'Tuesday'
              break
            case 3:
              this.dayOfWeek = 'Wednesday'
              break
            case 4:
              this.dayOfWeek = 'Thursday'
              break
            case 5:
              this.dayOfWeek = 'Friday'
              break
            case 6:
              this.dayOfWeek = 'Saturday'
          }
        }
      })
      await class_managerment
        .getStudentAttendance(this.scheduleID)
        .then(res => {
          if (res.studentRegistedBySchedules.length) {
            this.students = res.studentRegistedBySchedules
          } else {
            this.errorDialog = true
          }
        })
        .catch(() => {
          this.errorDialog = true
        })
      this.isLoading = false
    },
    attendanceAll() {
      if (this.attendanceAllValue == 'Attended') this.attendanceAllValue = 'Absent'
      else if (this.attendanceAllValue == 'Absent') this.attendanceAllValue = ''
      else this.attendanceAllValue = 'Attended'
      this.students.forEach(x => {
        x.attendanceStatus = this.attendanceAllValue
      })
    },
    homeworkAll() {
      if (this.homeworkAllValue == 'Yes') this.homeworkAllValue = 'No'
      else if (this.homeworkAllValue == 'No') this.homeworkAllValue = ''
      else this.homeworkAllValue = 'Yes'
      this.students.forEach(x => {
        x.homeworkStatus = this.homeworkAllValue
      })
    },
    async save() {
      const students = []
      this.students.forEach(x => {
        students.push({
          studentID: x.studentID,
          registrationID: x.registrationID,
          ordinalNo: parseInt(x.ordinalNo),
          isJoinToLesson:
            x.attendanceStatus == 'Attended'
              ? true
              : x.attendanceStatus === null || x.attendanceStatus === ''
              ? null
              : false,
          homeworkStatus: x.homeworkStatus,
          remarkAttendance: x.remarkAttendance,
          remarkHomeWork: x.remarkHomeWork,
        })
      })

      const body = {
        students: students,
      }
      await class_managerment
        .updateAttendanceStudent(this.classID, this.scheduleID, body)
        .then(res => {
          if (res) {
            this.dialog = true
            this.checkSuccess = true
            this.checkSuccess = false
            localStorage.setItem('isBackFromDetailDailyClasses', true)
            window.history.back()
            this.dialog = false
          }
        })
    },
    back() {
      localStorage.setItem('isBackFromDetailDailyClasses', true)
      window.history.back()
    },
    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.success {
  width: 300px;
  position: absolute;
  right: 0;
  bottom: 5%;
  z-index: 999;
}

.attendance-button {
  text-decoration: underline;
  cursor: pointer;
  color: #246aff;
}
.title-row,
.sub-title-row {
  color: #0c2e68;
  font-size: 16px;
}
.title-row {
  font-weight: 700;
  margin-left: 20px;
}
.sub-title-row {
  font-weight: 400;
  margin-left: 5px;
}
.checkbox-custom-right,
.checkbox-custom-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 10px;

  width: 56px;
  height: 25px;
  font-size: 14px;

  background: #ffffff;
  border: 1px solid #dae6f7;
  border-radius: 38px;

  z-index: 0;
}
.checkbox-custom-left {
  position: absolute;
  top: -4px;
  left: -30px;
}
.checkbox-custom-right {
  position: absolute;
  top: -4px;
  left: 30px;
}
.checkbox-custom-left-focus {
  border-color: #4adc88;
  background: #ddffe7;
  color: #0cb754;
}
.checkbox-custom-right-focus {
  border-color: #ec1c24;
  background: #ffeaeb;
  color: #ec1c24;
}
.text-field-custom-1 {
  position: absolute;
  top: -20px;
  left: -7px;
}
.text-field-custom-2 {
  position: absolute;
  top: -20px;
  left: 20px;
}
</style>
